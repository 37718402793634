import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Badge, Input, InputGroup, UncontrolledTooltip } from 'reactstrap';

const CircuitsMultiValueInput = ({
  id,
  name,
  placeholder,
  defaultValues,
  onChange,
  isDisabled,
  isDimmable = false
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultValues || []);
  const [currentValue, setCurrentValue] = useState('');
  const formContext = useFormContext();

  const addValue = (value) => {
    if (value === '') {
      formContext.setError(name, {
        type: 'value',
        message: t('Please enter a value')
      });

      return;
    }

    setValues([
      ...values,
      {
        label: value,
        dimmable: isDimmable
      }
    ]);
    setCurrentValue('');
  };

  const removeValue = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleCircuitsDragChange = (
    sourceId,
    sourceIndex,
    targetIndex,
    targetId
  ) => {
    const nextState = swap(values, sourceIndex, targetIndex);
    setValues(nextState);
  };

  const calculateContainerHeight = () => {
    if (values.length % 2 === 0) {
      return `${(values.length / 2) * 30}px`;
    } else {
      return `${Math.ceil(values.length / 2) * 30}px`;
    }
  };

  return (
    <>
      <InputGroup>
        {values && values.length === 0 && (
          <div className=" d-block w-100 ml-2 mt-1 mb-2">
            <small>{placeholder || 'No Values'}</small>
          </div>
        )}
        {!isDisabled
          ? values &&
            values.length > 0 && (
              <div className="w-100 mb-2">
                <GridContextProvider onChange={handleCircuitsDragChange}>
                  <GridDropZone
                    id="circuits"
                    boxesPerRow={2}
                    rowHeight={30}
                    style={{ height: calculateContainerHeight() }}
                  >
                    {values.map(
                      (value, index) =>
                        value.dimmable === isDimmable && (
                          <GridItem key={index} className="d-flex">
                            <UncontrolledTooltip target={`value-${index}`}>
                              {value.label}
                            </UncontrolledTooltip>
                            <Badge
                              id={`value-${index}`}
                              key={`value-${index}`}
                              color="secondary"
                              className="px-2 d-flex justify-content-between align-items-center"
                              style={{
                                cursor: 'pointer',
                                width: 'fit-content',
                                maxWidth: '130px',
                                gap: '0.2rem',
                                height: 'fit-content',
                                userSelect: 'none'
                              }}
                            >
                              <div className="text-truncate">
                                <span>{index + 1}.</span>{' '}
                                <span>{value.label}</span>
                              </div>
                              <div
                                onClick={() => removeValue(index)}
                                className="ml-1"
                                style={{ padding: '0.1rem' }}
                              >
                                <FontAwesomeIcon icon={['fas', 'times']} />
                              </div>
                            </Badge>
                          </GridItem>
                        )
                    )}
                  </GridDropZone>
                </GridContextProvider>
              </div>
            )
          : values &&
            values.length > 0 && (
              <div className="w-100 mb-2 d-flex flex-wrap gap-1">
                {values.map((value, index) => (
                  <Badge
                    key={`value-${index}`}
                    color="secondary"
                    className="px-2 d-flex justify-content-between"
                  >
                    <div className="text-truncate">
                      <span>{index + 1}.</span> <span>{value.label}</span>
                    </div>
                  </Badge>
                ))}
              </div>
            )}
        <Input
          type="text"
          name={name}
          id={id}
          placeholder={t('Enter circuit name and hit +')}
          onChange={(e) => setCurrentValue(e.target.value)}
          value={currentValue}
          disabled={isDisabled}
        />

        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => addValue(currentValue)}
          disabled={isDisabled}
        >
          +
        </button>
      </InputGroup>
    </>
  );
};

export default CircuitsMultiValueInput;
