import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import ProjectModalForm from '../form/ProjectModalForm';
import SideModal from './SideModal';

const emptyFormData = {
  clientId: null,
  name: '',
  address: '',
  notes: '',
  hourlyRate: 0,
  hourlyCost: 0,
  discount: 0,
  transportCost: 0
};

const ProjectModal = () => {
  const { t } = useTranslation();
  const { editProjectId, isOpenProjectModal, toggleProjectModal } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(emptyFormData);
  const [error, setError] = useState(undefined);

  const fetchProjectData = async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getProjectById(editProjectId);
      const newData = {
        clientId: response.data.clientId,
        name: response.data.name,
        address: response.data.address,
        notes: response.data.notes,
        hourlyRate: response.data.hourlyRate,
        hourlyCost: response.data.hourlyCost,
        discount: response.data.discount,
        transportCost: response.data.transportCost ?? 0
      };
      setFormData(newData);
    } catch (error) {
      toast.error(t('Unable to load project information'));
      setError(t('Unable to load project information'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editProjectId) {
      fetchProjectData();
    } else {
      setFormData(emptyFormData);
    }
  }, [editProjectId]);

  return (
    <SideModal
      isOpen={isOpenProjectModal}
      toggle={toggleProjectModal}
      title="Project Form"
      subtitle={
        editProjectId
          ? `${t('Editing project')} '${formData.name}'`
          : 'Create a new project'
      }
      icon="edit"
    >
      {!error && (
        <ProjectModalForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultValues={formData}
        />
      )}

      {error !== undefined && <p>{error}</p>}
    </SideModal>
  );
};

export default ProjectModal;
