export const ProjectStatus = {
  Received: 0,
  Configuration: 1,
  AdvancePayment: 2,
  Purchase: 3,
  Documentation: 4,
  Build: 5,
  Wiring: 6,
  QualityAssurance: 7,
  Programming: 8,
  VisualQualityAssurance: 9,
  Payment: 10,
  Shipped: 11
};

export const ProjectStatusText = {
  [ProjectStatus.Received]: 'new',
  [ProjectStatus.Configuration]: 'configuration',
  [ProjectStatus.AdvancePayment]: 'advance payment',
  [ProjectStatus.Purchase]: 'purchase',
  [ProjectStatus.Documentation]: 'documentation',
  [ProjectStatus.Build]: 'build',
  [ProjectStatus.Wiring]: 'wiring',
  [ProjectStatus.QualityAssurance]: 'quality assurance',
  [ProjectStatus.Programming]: 'programming',
  [ProjectStatus.VisualQualityAssurance]: 'visual quality assurance',
  [ProjectStatus.Payment]: 'payment',
  [ProjectStatus.Shipped]: 'shipped'
};

export const hiddenFromCollaboratorsStatuses = [
  ProjectStatus.Received,
  ProjectStatus.Configuration,
  ProjectStatus.AdvancePayment
];