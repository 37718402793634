export const relay = {
  en: {
    translations: {
      Relays: 'Relays',
      'Relay Form': 'Relay Form',
      'Circuits count': 'Circuits count',
      'Create relay': 'Create relay',
      'Relay saved successfully.': 'Relay saved successfully.',
      'Relay successfully deleted.': 'Relay successfully deleted.',
      General: 'General',
      Dimmable: 'Dimmable 230AC',
      LED: 'Dimmable 24DC',
      Heating: 'Heating',
      Actuator: 'Actuator',
      'No Actuator': 'No Actuator'
    }
  },
  ro: {
    translations: {
      Relays: 'Relee',
      'Relay Form': 'Formular Releu',
      'Circuits count': 'Număr de circuite',
      'Create relay': 'Creează releu',
      'Relay saved successfully.': 'Releu salvat cu succes.',
      'Relay successfully deleted.': 'Releu șters cu succes.',
      General: 'General',
      Dimmable: 'Reglabil',
      LED: 'LED',
      Heating: 'Încălzire',
      Actuator: 'Actuator',
      'No Actuator': 'Niciun Actuator'
    }
  },
  de: {
    translations: {
      Relays: 'Relais',
      'Relay Form': 'Relaisformular',
      'Circuits count': 'Schaltkreise zählen',
      'Create relay': 'Relais erstellen',
      'Relay saved successfully.': 'Relais erfolgreich gespeichert.',
      'Relay successfully deleted.': 'Relais erfolgreich gelöscht.',
      General: 'Allgemein',
      Dimmable: 'Dimmbar',
      LED: 'LED',
      Heating: 'Heizung',
      Actuator: 'Stellantrieb',
      'No Actuator': 'Kein Stellantrieb'
    }
  }
};
