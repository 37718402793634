import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';

import AppContext from '../../../context/Context';
import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import {
  hiddenFromCollaboratorsStatuses,
  ProjectStatus,
  ProjectStatusText
} from '../../../helpers/enums/statusEnum';
import { getDefaultSelectValue } from '../../../helpers/utils/getDefaultSelectValue';

export default function ProjectStatusSelect({ projectId, status }) {
  const { t } = useTranslation();
  const { setFetchData, user } = useContext(AppContext);

  const options = Object.keys(ProjectStatus)
    .filter((status) => user?.isAdmin || !hiddenFromCollaboratorsStatuses.includes(ProjectStatus[status]))
    .map((status) => {
      return {
        value: ProjectStatus[status],
        label: t(ProjectStatusText[ProjectStatus[status]])
      };
    });

  const handleStatusChange = async (option) => {
    if (option?.value !== status) {
      try {
        await projectApi.updateProjectStatus(projectId, option.value);
        toast.success(t('Project status updated'));
        setFetchData(true);
      } catch (error) {
        showErrorsFromRequest(error, t);
      }
    }

    return null;
  };

  return (
    <div>
      <Select
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onChange={(option) => handleStatusChange(option)}
        value={getDefaultSelectValue(status, options)}
        className="text-capitalize"
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%'
          })
        }}
      />
    </div>
  );
}
