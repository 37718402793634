import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Input, Table } from 'reactstrap';

import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProjectStatus, ProjectStatusText } from '../../../../helpers/enums/statusEnum';
import { getStatusColor } from '../../../../helpers/utils/utils';
import CheckUserPermission from '../../../../hoc/checkUserPermissions';
import React, { useContext, useState } from 'react';
import { projectApi } from '../../../../helpers/api/projectApi';
import { toast } from 'react-toastify';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { productHistoryCommentsApi } from '../../../../helpers/api/historyCommentsApi';
import AppContext from '../../../../context/Context';
import ButtonIcon from '../../../../components/common/ButtonIcon';

export default function HistoryTable({ entries, projectId, setUpdateComments }) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const [editingEntry, setEditingEntry] = useState(null);
  const [editedComment, setEditedComment] = useState('');
  const [editedIsPublic, setEditedIsPublic] = useState(false);

  const handleEditClick = (entry) => {
    setEditingEntry(entry.id);
    setEditedComment(entry.comments);
    setEditedIsPublic(entry.isPublic);
  };

  const handleSave = async (commentId) => {
    try {
      await productHistoryCommentsApi.updateHistoryCommentById({
        id: commentId,
        isPublic: editedIsPublic,
        comments: editedComment,
        projectId: projectId
      });
      toast.success(t('Comment updated successfully'));
      setUpdateComments(true);
      setEditingEntry(null);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const handleCancel = () => {
    setEditingEntry(null);
  };

  const handleFileDelete = async (commentId) => {
    try {
      await productHistoryCommentsApi.deleteHistoryCommentById(projectId, commentId);
      toast.success(t('Comment deleted successfully'));
      setUpdateComments(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom border-200">
        <thead className="bg-200 text-900">
          <tr>
            <th className="border-0">{t('Date')}</th>
            <CheckUserPermission
              permissions={[
                PermissionType.PROJECT_DETAILS_HISTORY.CAN_SEE_PUBLIC_COLUMN
              ]}
            >
              <th className="border-0">Public</th>
            </CheckUserPermission>
            {/*TODO: get history type from backend*/}
            {/*<th className="border-0">Type</th>*/}
            <th className="border-0">Status</th>
            <th className="border-0">{t('Comments')}</th>
            {user?.isAdmin && <th className="border-0">{t('Actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {entries && entries.length > 0 ? (
            entries.map((entry) => (
              <tr key={entry.id}>
                <td className="border-200">
                  {moment(entry.createdAt).format('Do MMM YY, HH:mm')}
                </td>
                <CheckUserPermission
                  permissions={[
                    PermissionType.PROJECT_DETAILS_HISTORY
                      .CAN_SEE_CUSTOMER_VISIBLE_CHECKBOX
                  ]}
                >
                  <td className="border-200">
                    {editingEntry === entry.id ? (
                      <Input
                        type="checkbox"
                        checked={editedIsPublic}
                        onChange={(e) => setEditedIsPublic(e.target.checked)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={entry.isPublic ? 'eye' : 'eye-slash'}
                      />
                    )}
                  </td>
                </CheckUserPermission>
                {/*TODO: get history type from backend*/}
                {/*<td className="align-middle text-center border-200">System</td>*/}
                <td className="border-200">
                  <Badge
                    color={`${getStatusColor(entry.status)}`}
                    className="mr-2 text-capitalize"
                    pill
                  >
                    {entry.status != null
                      ? t(ProjectStatusText[entry.status])
                      : ''}
                  </Badge>
                </td>
                <td className="border-200" style={{maxWidth: '300px'}}>
                  {editingEntry === entry.id ? (
                    <Input
                      type="text"
                      value={editedComment}
                      onChange={(e) => setEditedComment(e.target.value)}
                    />
                  ) : (
                    entry.comments
                  )}
                </td>
                {user?.isAdmin && (
                  <td className="border-200 text-nowrap">
                    {editingEntry === entry.id ? (
                      <>
                        <Button
                          size="sm"
                          color="success"
                          className="mr-2"
                          onClick={() => handleSave(entry.id)}
                        >
                          <FontAwesomeIcon icon="save" className="text-white" />{' '}
                          {t('Save')}
                        </Button>
                        <Button
                          size="sm"
                          color="secondary"
                          onClick={handleCancel}
                        >
                          <FontAwesomeIcon
                            icon="times"
                            className="text-white"
                          />{' '}
                          {t('Cancel')}
                        </Button>
                      </>
                    ) : (
                      <>
                        <ButtonIcon
                          icon="edit"
                          size="sm"
                          className="mr-2"
                          color="falcon-default"
                          onClick={() => handleEditClick(entry)}
                        >
                          {t('Edit')}
                        </ButtonIcon>
                        <Button
                          size="sm"
                          className="border-300 text-600 bg-danger"
                          onClick={() => handleFileDelete(entry.id)}
                        >
                          <FontAwesomeIcon
                            icon="trash"
                            className="text-white"
                          />
                        </Button>
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="align-middle text-center border-200">
                <i>{t('No comments posted yet')} ... </i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
