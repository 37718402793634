import z from 'zod';

export const projectFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Project name is required' })
    .max(50, { message: 'Project name is too long' }),
  clientId: z.number(),
  address: z.string().trim().optional(),
  notes: z.string().trim().optional(),
  discount: z.coerce.number(),
  hourlyRate: z.coerce.number(),
  hourlyCost: z.coerce.number(),
  transportCost: z.coerce.number({
    invalid_type_error: 'Transport cost is required',
    required_error: 'Transport cost is required'
  })
});
