import moment from 'moment';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardDeck, Col, Row } from 'reactstrap';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import { hiddenFromCollaboratorsStatuses, ProjectStatus } from '../../helpers/enums/statusEnum';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import DifferentLanguageWarning from './components/DifferentLanguageWarning';
import DocumentationButtons from './components/DocumentationButtons';
import History from './components/History/History';
import OfferSection from './components/OfferSection/OfferSection';
import ProjectDetailsCard from './components/ProjectDetailsCard';
import ProjectInformationCard from './components/ProjectInformationCard';
import ProjectQuickStatusCard from './components/ProjectQuickStatusCard';
import SharedFiles from './components/SharedFiles';

export const ProjectContext = createContext({
  documentationInfo: null,
  setDocumentationInfo: () => {}
});

const ProjectScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchData, setFetchData } = useContext(AppContext);
  const { projectId } = useParams();
  const { user } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [documentationInfo, setDocumentationInfo] = useState(null);

  const displayChangeStatusSection = () => {
    return !!(user?.isAdmin || (project?.status && !hiddenFromCollaboratorsStatuses.includes(project.status)));
  };
  
  const fetchProject = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
      setFetchData(false);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setFetchData, t]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject, fetchData]);

  if (!project) {
    return null;
  }

  return (
    <ProjectContext.Provider
      value={{ documentationInfo, setDocumentationInfo }}
    >
      <>
        <PageHeader
          title={project.name}
          controls={() => (
            <div className="d-flex justify-content-end align-items-center gap-3 flex-wrap">
              <CheckUserPermissions
                permissions={[PermissionType.SWITCHBOARD.VIEW_DETAILS]}
              >
                <ButtonIcon
                  icon="cog"
                  color="falcon-primary"
                  size="sm"
                  className="flex-nowrap text-nowrap"
                  onClick={() => navigate(`/projects/${projectId}/switchboard`)}
                >
                  {t('Switchboard')}
                </ButtonIcon>
              </CheckUserPermissions>
              <div className="d-flex flex-nowrap align-items-center gap-3">
                <div>
                  {(project.status >= ProjectStatus.AdvancePayment ||
                    user?.isAdmin) && (
                    <CheckUserPermissions
                      permissions={[PermissionType.DOCUMENTATION.VIEW]}
                    >
                      <DocumentationButtons
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        project={project}
                      />
                    </CheckUserPermissions>
                  )}
                </div>
                <DifferentLanguageWarning />
              </div>
            </div>
          )}
        />
        <CardDeck>
          <ProjectDetailsCard
            title="Status"
            status={project.status}
            subtitle={`${t('Last Update')} ${moment(project.updatedAt).format('DD MMM YYYY HH:mm:ss')}`}
          />
          <CheckUserPermissions
            permissions={[PermissionType.PROJECT_DETAILS.STATUS_MODIFY]}
          >
            {displayChangeStatusSection() && (
              <ProjectQuickStatusCard
                projectId={projectId}
                status={project.status}
              />
            )}
          </CheckUserPermissions>
        </CardDeck>
        <ProjectInformationCard projectId={projectId} project={project} />
        <OfferSection project={project} fetchProject={fetchProject} />
        <Row>
          <Col className="mt-3">
            <History projectId={projectId} />
          </Col>
          <Col md={5} lg={4} className="mt-3">
            <SharedFiles projectId={project.id} />
          </Col>
        </Row>
      </>
    </ProjectContext.Provider>
  );
};

export default ProjectScreen;
