import axios from '../axios/axios.service';

export const productHistoryCommentsApi = {
  getHistoryCommentsByProjectId: async (projectId) => {
    return await axios(`/projects/${projectId}/history-comments`);
  },
  addHistoryCommentsByProjectId: async (projectId, data) => {
    return await axios.post(`/projects/${projectId}/history-comments`, data);
  },
  deleteHistoryCommentById: async (projectId, commentId) => {
    return await axios.delete(`/projects/${projectId}/history-comments/${commentId}`);
  },
  updateHistoryCommentById: async (data) => {
    return await axios.put(`/projects/${data.projectId}/history-comments/${data.id}`, data);
  }
};