import { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import AppContext from '../../context/Context';
import { clientApi } from '../../helpers/api/clientApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { getDefaultSelectValue } from '../../helpers/utils/getDefaultSelectValue';
import ButtonIcon from '../common/ButtonIcon';
import RequiredStarLabel from '../common/requiredStarLabel';
import FormGroupNote from './FormGroupNote';

export default function ClientSelect({ isLoading, isRequired = false }) {
  const { t } = useTranslation();
  const { toggleClientModal, fetchData, setFetchData } = useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [clients, setClients] = useState([]);

  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [isFetching, setIsFetching] = useState(false);

  const fetchClients = async () => {
    let currentPage = 1;
    let fetchedClients = [];
    setIsFetching(true);
    while (true) {
      try {
        const response = await clientApi.getClients({
          page: currentPage,
          perPage: 1000
        });

        const { items, page, lastPage } = response.data;

        fetchedClients = [...fetchedClients, ...items];
        currentPage++;

        if (lastPage === 0 || page === lastPage) {
          break;
        }
      } catch (error) {
        showErrorsFromRequest(error, t);
        break;
      }
    }

    setClients(fetchedClients);

    fetchedClients = fetchedClients.map((client) => ({
      value: client.id,
      label: client.name
    }));

    setIsFetching(false);
    setOptions(fetchedClients);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchClients();
      setFetchData(false);
    }
  }, [fetchData]);

  return (
    <FormGroup>
      <Label htmlFor="clientId">
        {t('Search Client')} {isRequired && <RequiredStarLabel />}
        <ButtonIcon
          onClick={() => toggleClientModal()}
          icon="plus"
          color="link"
          className="ml-1 rounded-capsule btn-sm"
        >
          {t('Create new client')}
        </ButtonIcon>
      </Label>
      <Controller
        name="clientId"
        control={control}
        render={({ field }) => (
          <Select
            id="clientId"
            name="clientId"
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => {
              field.onChange(option ? option.value : null);
              const selectedClient = clients.find(it => it.id === option?.value);
              if (selectedClient) {
                setValue('hourlyRate', selectedClient.hourlyRate || 0);
                setValue('hourlyCost', selectedClient.hourlyCost || 0);
              }
            }}
            placeholder={t('Select a client')}
            value={getDefaultSelectValue(field.value, options)}
            isSearchable
            isClearable
            isDisabled={isLoading || isFetching}
          />
        )}
      />
      {errors.clientId && (
        <FormGroupNote type="warning">{errors.clientId.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
